import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import './scss/style.scss';
import "react-datepicker/dist/react-datepicker.css";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/auth/login/Login'));
const Page404 = React.lazy(() => import('./views/auth/page404/Page404'));
const Page500 = React.lazy(() => import('./views/auth/page500/Page500'));

class App extends Component {
  
  componentDidUpdate(prevProps) {
    if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
      if (this.props.isAuthenticated) {
        this.props.history.push('/app/dashboard');
      } else {
        this.props.history.push('/login');
      }
    }  
    
		/*
    if(this.props.isAuthenticated && this.props.userData && this.props.userData.me.role === 'admin'){
      let dashboard_visibility = localStorage.getItem(`dashboard_visibility_${this.props.userData.me.id}`);
			console.log('dashboard_visibility', dashboard_visibility);

      if(dashboard_visibility){
        dashboard_visibility = JSON.parse(dashboard_visibility); 
        const exits = dashboard_visibility.filter((visibility) => visibility === this.props.location.pathname);
				console.log('exits', exits);
    
        if (exits.length == 0) {
          window.location.href = '/';
        }
      }
    }
		*/
  }

  render() {
    return (
      <React.Suspense fallback={loading}>
        <Switch>
          <Route path="/" exact render={props => <Redirect to="/app" />} />
          <Route path="/app" name="Home" render={props => <TheLayout {...props} />} />
          {
            !this.props.isAuthenticated &&
            <React.Fragment>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            </React.Fragment>
          }
          <Route path="/500" name="Page 500" render={props => <Page500 {...props} />} />
          <Route path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route path="*" name="Page 404" render={props => <Page404 {...props} />} />
        </Switch>
      </React.Suspense>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userData: state.auth.userData
})
export default connect(mapStateToProps)(withRouter(App));
