import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteMediaApi,
  fetchMediaDetail,
  fetchMediaList,
  uploadMediaApi,
} from "src/services/media.services";

const initialState = {
  loading: false,
  gridData: {
    count: null,
    page: 1,
    next_url: null,
    previous_url: null,
    results: [],
  },
  selectedMedia: null,
  errors: null,
  isUploaded: false,
  isShowMore: false,
  count: 0,
};

export const FetchMedia = createAsyncThunk(
  "fetch/media-list",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchMediaList(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchMediaDetails = createAsyncThunk(
  "fetch/media/details",
  async (mediaID, { rejectWithValue }) => {
    try {
      return await fetchMediaDetail(mediaID);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteMedia = createAsyncThunk(
  "delete/media",
  async (mediaID, { rejectWithValue }) => {
    try {
      const response = await deleteMediaApi(mediaID);
      return { ...response.data, deleteID: mediaID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UploadMediaAction = createAsyncThunk(
  "upload/medias",
  async (params, { rejectWithValue }) => {
    try {
      return await uploadMediaApi(params);
    } catch (error) {
      return rejectWithValue(error.response.data.detail || "");
    }
  }
);
const mediaSlice = createSlice({
  name: "media",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchMedia.pending]: (state, action) => {
      state.loading = true;
      // state.gridData = null;
      state.errors = null;
      state.selectedMedia = null;
      state.isUploaded = false;
      state.isShowMore = false;
    },
    [FetchMedia.fulfilled]: (state, action) => {
      state.loading = false;
      let data = action.payload;
      if (state.gridData && state.gridData.results) {
        data = {
          ...action.payload,
          results: [...state.gridData.results, ...action.payload.results],
        };
      }
      if (state.gridData && state.gridData.count !== data.results.length) {
        // if (state.gridData.count - state.count === data.results.length) {

        //   state.isShowMore = false;
        // }else{
        //   state.isShowMore = true;
        // }
        if (data?.next_url) {
          state.isShowMore = true;
        } else {
          state.isShowMore = false;
        }
      }
      state.gridData = data;
    },
    [FetchMedia.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
      state.isShowMore = false;
    },
    [FetchMediaDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedMedia = null;
      state.errors = null;
    },
    [FetchMediaDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedMedia = action.payload;
    },
    [FetchMediaDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UploadMediaAction.pending]: (state, action) => {
      state.loading = true;
      state.isUploaded = false;
      state.errors = null;
    },
    [UploadMediaAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUploaded = true;
      let data = {
        ...state.gridData,
        count: state.gridData.count + 1,
        results: [action.payload, ...state.gridData.results],
      };
      state.gridData = data;
    },
    [UploadMediaAction.rejected]: (state, action) => {
      state.loading = false;
      state.isUploaded = false;
      state.errors = action.payload || "Please Select bellow 1MB file";
    },
    [DeleteMedia.pending]: (state, action) => {
      state.loading = true;
      state.errors = null;
    },
    [DeleteMedia.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.count = state.count += 1;
      state.gridData.results = state.gridData.results.filter(
        (item) => item.id !== action.payload.deleteID
      );
    },
    [DeleteMedia.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const mediaSelector = (state) => state.media;
export default mediaSlice.reducer;
