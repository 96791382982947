import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createAmenities,
  deleteAmenities,
  fetchAmenitiesDetail,
  fetchAmenities,
  updateAmenities,
} from "src/services/amenities.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedAmenities: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchAmenities = createAsyncThunk(
  "fetch/Amenities",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchAmenities(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchAmenitiesDetails = createAsyncThunk(
  "fetch/Amenities/details",
  async (AmenitiesID, { rejectWithValue }) => {
    try {
      return await fetchAmenitiesDetail(AmenitiesID);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const CreateAmenities = createAsyncThunk(
  "create/Amenities",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createAmenities(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UpdateAmenities = createAsyncThunk(
  "update/Amenities",
  async (params, { rejectWithValue }) => {
    const value = params.values;
    try {
      const response = await updateAmenities(params.id, value);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteAmenities = createAsyncThunk(
  "delete/Amenities",
  async (AmenitiesID, { rejectWithValue }) => {
    try {
      const response = await deleteAmenities(AmenitiesID);
      return { ...response.data, deleteID: AmenitiesID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const AmenitiesSlice = createSlice({
  name: "amenities",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchAmenities.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDelete = false;
      state.selectedAmenities = null;
    },
    [FetchAmenities.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchAmenities.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchAmenitiesDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedAmenities = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchAmenitiesDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedAmenities = action.payload;
    },
    [FetchAmenitiesDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [CreateAmenities.pending]: (state, action) => {
      state.loading = true;
      state.isCreated = false;
      state.errors = null;
    },
    [CreateAmenities.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCreated = true;
    },
    [CreateAmenities.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UpdateAmenities.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdateAmenities.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdateAmenities.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeleteAmenities.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteAmenities.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.gridData.results = state.gridData.results.filter(
        (item) => item.id !== action.payload.deleteID
      );
    },
    [DeleteAmenities.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const amenitiesSelector = (state) => state.amenities;
export default AmenitiesSlice.reducer;
