import apiClient from "./axios";

export const fetchFeatures = async (params) => {
  const response = await apiClient.get(`admin/stores/features/`, { params });
  return response.data;
};

export const fetchFeatureDetail = async (FeatureID) => {
  const response = await apiClient.get(`admin/stores/features/${FeatureID}`);
  return response.data;
};

export const updateFeature = async (FeatureID, params) => {
  const response = await apiClient.put(
    `admin/stores/features/${FeatureID}/`,
    params
  );
  return response.data;
};

export const createFeature = async (params) => {
  const response = await apiClient.post(`admin/stores/features/`, params);
  return response.data;
};

export const deleteFeature = async (FeatureID, params) => {
  const response = await apiClient.delete(
    `admin/stores/features/${FeatureID}`,
    params
  );
  return response.data;
};
