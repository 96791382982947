import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createUser,
  deleteUser,
  fetchUserDetail,
  fetchUsers,
  updateUser,
} from "src/services/users.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedUser: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchUsers = createAsyncThunk(
  "fetch/users",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchUsers(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchUserDetails = createAsyncThunk(
  "fetch/users/details",
  async (userID, { rejectWithValue }) => {
    try {
      return await fetchUserDetail(userID);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const CreateUser = createAsyncThunk(
  "create/user",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createUser(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UpdateUser = createAsyncThunk(
  "update/user",
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateUser(params.id, params.values);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteUser = createAsyncThunk(
  "delete/user",
  async (userID, { rejectWithValue }) => {
    try {
      const response = await deleteUser(userID);
      return { ...response.data, deleteID: userID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchUsers.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.selectedUser = null;
    },
    [FetchUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchUsers.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchUserDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedUser = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchUserDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedUser = action.payload;
    },
    [FetchUserDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [CreateUser.pending]: (state, action) => {
      state.loading = true;
      state.isCreated = false;
      state.errors = null;
    },
    [CreateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCreated = true;
    },
    [CreateUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UpdateUser.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdateUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeleteUser.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.gridData.results = state.gridData.results.filter(
        (item) => item.id !== action.payload.deleteID
      );
    },
    [DeleteUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

// export const { removeAuth } = userSlice.actions
export const userSelector = (state) => state.user;
export default userSlice.reducer;
