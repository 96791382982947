import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createCategory, deleteCategory, fetchCategories, fetchCategoryDetail, updateCategory } from 'src/services/categories.services';

const initialState = {
  loading: false,
  gridData: null,
  selectedTags: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
}

export const FetchCategories = createAsyncThunk('fetch/posts/categories', async (params, { rejectWithValue }) => {
  try {
    return await fetchCategories(params);
  } catch (error) {
    return rejectWithValue(error.response.data || "")
  }
})

export const FetchCategoryDetails = createAsyncThunk('fetch/posts/categories/details', async (CategoryID, { rejectWithValue }) => {
  try {
    return await fetchCategoryDetail(CategoryID);

  } catch (error) {
    return rejectWithValue(error.response.data || "")
  }
})

export const CreateCategory = createAsyncThunk('create/posts/category', async (params, { rejectWithValue }) => {
  try {
    const response = await createCategory(params)
    return response.data
  } catch (error) {
    return rejectWithValue(error.response.data || "")
  }
})

export const UpdateCategory = createAsyncThunk('update/posts/category', async (params, { rejectWithValue }) => {
  try {
    const response = await updateCategory(params.id, params.values)
    return response
  } catch (error) {
    return rejectWithValue(error.response.data || "")
  }
})

export const DeleteCategory = createAsyncThunk(
  "delete/posts/category",
  async (CategoryID, { rejectWithValue }) => {
    try {
      const response = await deleteCategory(CategoryID);
      return { ...response.data, deleteID: CategoryID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const CategoriesReducer = createSlice({
  name: 'Category',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchCategories.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.selectedTags = null;
    },
    [FetchCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchCategories.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchCategoryDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedTags = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchCategoryDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedTags = action.payload;
    },
    [FetchCategoryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [CreateCategory.pending]: (state, action) => {
      state.loading = true;
      state.isCreated = false;
      state.errors = null;
    },
    [CreateCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCreated = true;
    },
    [CreateCategory.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UpdateCategory.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdateCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdateCategory.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeleteCategory.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.gridData.results = state.gridData.results.filter(
        (item) => item.id !== action.payload.deleteID
      );
    },
    [DeleteCategory.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
})

// export const { removeAuth } = TagsReducer.actions
export const categoriesSelector = state => state.categoryList;
export default CategoriesReducer.reducer;