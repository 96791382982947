import apiClient from "./axios"

export const fetchMediaList = async (params) => {
    const response = await apiClient.get(`admin/media-entities/`, { params });
    return response.data
}

export const fetchMediaDetail = async (id) => {
    const response = await apiClient.get(`admin/media-entities/${id}/`);
    return response.data
}

export const deleteMediaApi = async (id) => {
    const response = await apiClient.delete(`admin/media-entities/${id}/`);
    return response.data
}

export const uploadMediaApi = async (params) => {
    const response = await apiClient.post(`admin/media-entities/bulk-upload/`, params);
    return response.data;
  };