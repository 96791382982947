import apiClient from "./axios";

export const fetchSponsor = async (params) => {
  const response = await apiClient.get(`admin/events/sponsors/`, {
    params,
  });
  return response.data;
};

export const fetchSponsorDetail = async (userID) => {
  const response = await apiClient.get(`admin/events/sponsors/${userID}/`);
  return response.data;
};

export const updateSponsor = async (userID, params) => {
  const response = await apiClient.put(
    `admin/events/sponsors/${userID}/`,
    params
  );
  return response.data;
};

export const createSponsor = async (params) => {
  const response = await apiClient.post(`admin/events/sponsors/`, params);
  return response.data;
};

export const deleteSponsor = async (SponsorID, params) => {
  const response = await apiClient.delete(
    `admin/events/sponsors/${SponsorID}`,
    params
  );
  return response.data;
};