import apiClient from "./axios";

export const fetchProducts = async (params) => {
  const response = await apiClient.get(`admin/works/products/`, { params });
  return response.data;
};

export const fetchProductDetail = async (productID) => {
  const response = await apiClient.get(`admin/works/products/${productID}`);
  return response.data;
};

export const updateProduct = async (productID, params) => {
  const response = await apiClient.put(
    `admin/works/products/${productID}/`,
    params
  );
  return response.data;
};

export const createProduct = async (params) => {
  const response = await apiClient.post(`admin/works/products/`, params);
  return response.data;
};

export const deleteProduct = async (productID, params) => {
  const response = await apiClient.delete(
    `admin/works/products/${productID}`,
    params
  );
  return response.data;
};
