import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPost,
  deletePost,
  deletePostReport,
  fetchPostDetail,
  fetchPostReportDetail,
  fetchPostReports,
  fetchPosts,
  updatePost,
} from "src/services/posts.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedPost: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchPost = createAsyncThunk(
  "fetch/posts",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchPosts(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);
export const FetchPostReports = createAsyncThunk(
  "fetch/posts/port_reports",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchPostReports(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchPostReportDetails = createAsyncThunk(
  "fetch/posts/port_reports/details",
  async (postId, { rejectWithValue }) => {
    try {
      const response = await fetchPostReportDetail(postId);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);
export const FetchPostDetails = createAsyncThunk(
  "fetch/posts/details",
  async (postId, { rejectWithValue }) => {
    try {
      const response = await fetchPostDetail(postId);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const CreatePost = createAsyncThunk(
  "create/posts",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createPost(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UpdatePost = createAsyncThunk(
  "update/posts",
  async (params, { rejectWithValue }) => {
    try {
      const response = await updatePost(params.id, params.body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeletePost = createAsyncThunk(
  "delete/posts",
  async (postId, { rejectWithValue }) => {
    try {
      const response = await deletePost(postId);
      return { ...response.data, deleteID: postId };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);
export const DeletePostReport = createAsyncThunk(
  "delete/posts/post_report",
  async (postId, { rejectWithValue }) => {
    try {
      const response = await deletePostReport(postId);
      return { ...response.data, deleteID: postId };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const PostReducer = createSlice({
  name: "Post",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchPost.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.selectedPost = null;
    },
    [FetchPost.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchPost.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchPostReports.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.selectedPost = null;
    },
    [FetchPostReports.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchPostReports.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchPostDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedPost = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchPostDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedPost = action.payload;
    },
    [FetchPostDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchPostReportDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedPost = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchPostReportDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedPost = action.payload;
    },
    [FetchPostReportDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [CreatePost.pending]: (state, action) => {
      state.loading = true;
      state.isCreated = false;
      state.errors = null;
    },
    [CreatePost.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCreated = true;
    },
    [CreatePost.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UpdatePost.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdatePost.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdatePost.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeletePost.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeletePost.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      // state.gridData.results = state.gridData.results.filter(
      //   (item) => item.id !== action.payload.deleteID
      // );
    },
    [DeletePost.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeletePostReport.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeletePostReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      // state.gridData.results = state.gridData.results.filter(
      //   (item) => item.id !== action.payload.deleteID
      // );
    },
    [DeletePostReport.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

// export const { removeAuth } = TagsReducer.actions
export const postsSelector = (state) => state.post;
export default PostReducer.reducer;
