import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createSponsor,
  deleteSponsor,
  fetchSponsor,
  fetchSponsorDetail,
  updateSponsor,
} from "src/services/sponsor.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedSponsors: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchAppSponsors = createAsyncThunk(
  "fetch/sponsor",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchSponsor(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchSponsorsDetails = createAsyncThunk(
  "fetch/sponsor/details",
  async (userID, { rejectWithValue }) => {
    try {
      return await fetchSponsorDetail(userID);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const CreateSponsors = createAsyncThunk(
  "create/sponsor",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createSponsor(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UpdateSponsors = createAsyncThunk(
  "update/sponsor",
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateSponsor(params.id, params.values);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteSponsor = createAsyncThunk(
  "delete/sponsor",
  async (eventID, { rejectWithValue }) => {
    try {
      const response = await deleteSponsor(eventID);
      return { ...response.data, deleteID: eventID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const SponsorReducer = createSlice({
  name: "Sponsors",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchAppSponsors.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.selectedSponsors = null;
    },
    [FetchAppSponsors.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchAppSponsors.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchSponsorsDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedSponsors = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchSponsorsDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedSponsors = action.payload;
    },
    [FetchSponsorsDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [CreateSponsors.pending]: (state, action) => {
      state.loading = true;
      state.isCreated = false;
      state.errors = null;
    },
    [CreateSponsors.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCreated = true;
    },
    [CreateSponsors.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UpdateSponsors.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdateSponsors.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdateSponsors.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeleteSponsor.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteSponsor.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.gridData.results = state.gridData.results.filter(
        (item) => item.id !== action.payload.deleteID
      );
    },
    [DeleteSponsor.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

// export const { removeAuth } = SponsorReducer.actions
export const appSponsorsSelector = (state) => state.sponsorList;
export default SponsorReducer.reducer;
