import apiClient from "./axios";

export const fetchTags = async (params) => {
  const response = await apiClient.get(`admin/events/tags/`, {
    params,
  });
  return response.data;
};

export const fetchTagsDetail = async (userID) => {
  const response = await apiClient.get(`admin/events/tags/${userID}/`);
  return response.data;
};

export const updateTags = async (userID, params) => {
  const response = await apiClient.put(
    `admin/events/tags/${userID}/`,
    params
  );
  return response.data;
};

export const createTags = async (params) => {
  const response = await apiClient.post(`admin/events/tags/`, params);
  return response.data;
};

export const deleteTag = async (TagID, params) => {
  const response = await apiClient.delete(
    `admin/events/tags/${TagID}`,
    params
  );
  return response.data;
};

