import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getPostsCount } from "src/services/postAnalytics.services";

const countState = {
  loading: false,
  postCount: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchPostsCount = createAsyncThunk(
  "fetch/getPostCount",
  async (params, { rejectWithValue }) => {
    try {
      return await getPostsCount(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const PostCountSlice = createSlice({
  name: "postCount",
  initialState: countState,
  reducers: {},
  extraReducers: {
    [FetchPostsCount.pending]: (state, action) => {
      state.loading = true;
      state.postCount = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDelete = false;
      state.selectedFeature = null;
    },
    [FetchPostsCount.fulfilled]: (state, action) => {
      state.loading = false;
      state.postCount = action.payload;
    },
    [FetchPostsCount.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const PostCountSelector = (state) => state.postCount;

export const PostCountReducer = PostCountSlice.reducer;
