import apiClient from "./axios";

export const fetchMeetRequests = async (params) => {
  const response = await apiClient.get(`admin/meet-requests/`, {
    params,
  });

  return response.data;
};

// export const fetchPostDetail = async (postId) => {
//   const response = await apiClient.get(`admin/posts/${postId}/`);

//   return response.data;
// };

// export const updatePost = async (postId, params) => {
//   const response = await apiClient.put(
//     `admin/posts/${postId}/`,
//     params
//   );
//   return response.data;
// };

// export const createPost = async (params) => {
//   const response = await apiClient.post(`admin/posts/`, params);
//   return response.data;
// };

// export const deletePost = async (postId, params) => {
//   const response = await apiClient.delete(
//     `admin/posts/${postId}/`,
//     params
//   );
//   return response.data;
// };
