import axios from "axios";
import apiClient from "./axios";

export const fetchAppUsers = async (params) => {
  const response = await apiClient.get(`admin/users/customers/`, {
    params,
  });
  return response.data;
};

export const fetchAppUserDetail = async (userID) => {
  const response = await apiClient.get(`admin/users/customers/${userID}/`);
  
  const data = {
    'merchantID': 86,
    'phone': response.data.mobile_no ? response.data.mobile_no : '9167000410'
  }

  const dotPeResponse = await dotPeOrder(data)
  response.data.orders = dotPeResponse;

  return response.data;
};

export const dotPeOrder = async (data) => {
  var config = {
    method: 'post',
    url: 'https://api.dotdukaan.com/api/userservice/user/token',
    headers: { 
      'client_id': data.merchantID, 
      'Content-Type': 'application/json'
    },
    data : JSON.stringify({userPhone: data.phone})
  };
  
  const tokenResponse = await axios(config);

  if (tokenResponse.data.status === true && tokenResponse.data.token){
    config = {
      method: 'post',
      url: 'https://api.dotpe.in/api/order/user/orders',
      headers: { 
        'Authorization': `Bearer ${tokenResponse.data.token}`
      },
      data: data
    };

    const orders = await axios(config);
    return orders.data.orders;
  }

  return {}
};


export const updateAppUser = async (userID, params) => {
  const response = await apiClient.put(
    `admin/users/customers/${userID}/`,
    params
  );
  return response.data;
};

export const createAppUser = async (params) => {
  const response = await apiClient.post(`admin/users/customers/`, params);
  return response.data;
};

export const deleteAppUser = async (AppUserID, params) => {
  const response = await apiClient.delete(
    `admin/users/customers/${AppUserID}`,
    params
  );
  return response.data;
};
