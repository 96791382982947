import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createProduct,
  deleteProduct,
  fetchProductDetail,
  fetchProducts,
  updateProduct,
} from "src/services/workSocial.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedProduct: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchProduct = createAsyncThunk(
  "fetch/works/product",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchProducts(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchProductDetails = createAsyncThunk(
  "fetch/works/product/details",
  async (productID, { rejectWithValue }) => {
    try {
      return await fetchProductDetail(productID);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const CreateProduct = createAsyncThunk(
  "create/works/product",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createProduct(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UpdateProduct = createAsyncThunk(
  "update/works/product",
  async (params, { rejectWithValue }) => {
    const value = params.values;
    try {
      const response = await updateProduct(params.id, value);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteProduct = createAsyncThunk(
  "delete/works/product",
  async (productID, { rejectWithValue }) => {
    try {
      const response = await deleteProduct(productID);
      return {...response.data, deleteID: productID};
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const workSocialSlice = createSlice({
  name: "workSocial",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchProduct.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDelete = false;
      state.selectedProduct = null;
    },
    [FetchProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchProductDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedProduct = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchProductDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedProduct = action.payload;
    },
    [FetchProductDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [CreateProduct.pending]: (state, action) => {
      state.loading = true;
      state.isCreated = false;
      state.errors = null;
    },
    [CreateProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCreated = true;
    },
    [CreateProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UpdateProduct.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdateProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdateProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeleteProduct.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.gridData.results = state.gridData.results.filter((item) => item.id !== action.payload.deleteID);
    },
    [DeleteProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const workSocialSelector = (state) => state.workSocial;
export default workSocialSlice.reducer;
