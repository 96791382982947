import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createTags, deleteTag, fetchTags, fetchTagsDetail, updateTags } from 'src/services/tags.services';

const initialState = {
    loading: false,
    gridData: null,
    selectedTags: null,
    errors: null,
    isCreated: false,
    isUpdated: false,
}

export const FetchAppTags = createAsyncThunk('fetch/tags', async (params, { rejectWithValue }) => {
    try {
        return await fetchTags(params);
    } catch (error) {
        return rejectWithValue(error.response.data || "")
    }
})

export const FetchTagsDetails = createAsyncThunk('fetch/tags/details', async (userID, { rejectWithValue }) => {
    try {
        return await fetchTagsDetail(userID);
       
    } catch (error) {
        return rejectWithValue(error.response.data || "")
    }
})

export const CreateTags = createAsyncThunk('create/tags', async (params, { rejectWithValue }) => {
    try {
        const response = await createTags(params)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data || "")
    }
})

export const UpdateTags = createAsyncThunk('update/tags', async (params, { rejectWithValue }) => {
    try {
        const response = await updateTags(params.id, params.values)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data || "")
    }
})

export const DeleteTag = createAsyncThunk(
    "delete/tags",
    async (eventID, { rejectWithValue }) => {
      try {
        const response = await deleteTag(eventID);
        return { ...response.data, deleteID: eventID };
      } catch (error) {
        return rejectWithValue(error.response.data || "");
      }
    }
  );

const TagsReducer = createSlice({
    name: 'Tags',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [FetchAppTags.pending]: (state, action) => {
            state.loading = true;
            state.gridData = null;
            state.errors = null;
            state.isCreated = false;
            state.isUpdated = false;
            state.selectedTags = null;
        },
        [FetchAppTags.fulfilled]: (state, action) => {
            state.loading = false;
            state.gridData = action.payload;
        },
        [FetchAppTags.rejected]: (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        },
        [FetchTagsDetails.pending]: (state, action) => {
            state.loading = true;
            state.selectedTags = null;
            state.errors = null;
            state.isCreated = false;
            state.isUpdated = false;
        },
        [FetchTagsDetails.fulfilled]: (state, action) => {
            state.loading = false;
            state.selectedTags = action.payload;
        },
        [FetchTagsDetails.rejected]: (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        },
        [CreateTags.pending]: (state, action) => {
            state.loading = true;
            state.isCreated = false;
            state.errors = null;
        },
        [CreateTags.fulfilled]: (state, action) => {
            state.loading = false;
            state.isCreated = true;
        },
        [CreateTags.rejected]: (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        },
        [UpdateTags.pending]: (state, action) => {
            state.loading = true;
            state.isUpdated = false;
            state.errors = null;
        },
        [UpdateTags.fulfilled]: (state, action) => {
            state.loading = false;
            state.isUpdated = true;
        },
        [UpdateTags.rejected]: (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        },
        [DeleteTag.pending]: (state, action) => {
            state.loading = true;
            state.isDelete = false;
            state.errors = null;
          },
          [DeleteTag.fulfilled]: (state, action) => {
            state.loading = false;
            state.isDelete = true;
            state.gridData.results = state.gridData.results.filter(
              (item) => item.id !== action.payload.deleteID
            );
          },
          [DeleteTag.rejected]: (state, action) => {
            state.loading = false;
            state.errors = action.payload;
          },
    },
})

// export const { removeAuth } = TagsReducer.actions
export const tagsSelector = state => state.tagsList;
export default TagsReducer.reducer;