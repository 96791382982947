import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getMeetRequestByCustomer,
  getMeetRequestByStatus,
  getMeetRequestByStore,
  getMeetRequestCount,
} from "src/services/meet.services";

const countState = {
  loading: false,
  meetCount: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};
const meetByCustomerState = {
  loading: false,
  meetByCustomer: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};
const meetByStatusState = {
  loading: false,
  meetByStatus: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};
const meetByStoreState = {
  loading: false,
  meetByStore: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchMeetCount = createAsyncThunk(
  "fetch/getMeetRequestCount",
  async (params, { rejectWithValue }) => {
    try {
      return await getMeetRequestCount(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);
export const FetchMeetRequestByCustomer = createAsyncThunk(
  "fetch/getMeetRequestByCustomer",
  async (params, { rejectWithValue }) => {
    try {
      return await getMeetRequestByCustomer(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);
export const FetchMeetRequestByStatus = createAsyncThunk(
  "fetch/getMeetRequestByStatus",
  async (params, { rejectWithValue }) => {
    try {
      return await getMeetRequestByStatus(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);
export const FetchMeetRequestByStore = createAsyncThunk(
  "fetch/getMeetRequestByStore",
  async (params, { rejectWithValue }) => {
    try {
      return await getMeetRequestByStore(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const MeetCountSlice = createSlice({
  name: "meeCount",
  initialState: countState,
  reducers: {},
  extraReducers: {
    [FetchMeetCount.pending]: (state, action) => {
      state.loading = true;
      state.meetCount = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDelete = false;
      state.selectedFeature = null;
    },
    [FetchMeetCount.fulfilled]: (state, action) => {
      state.loading = false;
      state.meetCount = action.payload;
    },
    [FetchMeetCount.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

const MeetByCustomerSlice = createSlice({
  name: "meetByCustomer",
  initialState: meetByCustomerState,
  reducers: {},
  extraReducers: {
    [FetchMeetRequestByCustomer.pending]: (state, action) => {
      state.loading = true;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDelete = false;
    },
    [FetchMeetRequestByCustomer.fulfilled]: (state, action) => {
      state.loading = false;
      state.meetByCustomer = action.payload;
    },
    [FetchMeetRequestByCustomer.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});
const MeetByStatusSlice = createSlice({
  name: "meetByStatus",
  initialState: meetByStatusState,
  reducers: {},
  extraReducers: {
    [FetchMeetRequestByStatus.pending]: (state, action) => {
      state.loading = true;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDelete = false;
    },
    [FetchMeetRequestByStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.meetByStatus = action.payload;
    },
    [FetchMeetRequestByStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});
const MeetByStoreSlice = createSlice({
  name: "meetByStore",
  initialState: meetByStoreState,
  reducers: {},
  extraReducers: {
    [FetchMeetRequestByStore.pending]: (state, action) => {
      state.loading = true;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDelete = false;
    },
    [FetchMeetRequestByStore.fulfilled]: (state, action) => {
      state.loading = false;
      state.meetByStore = action.payload;
    },
    [FetchMeetRequestByStore.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const MeetCountSelector = (state) => state.meetCount;
export const MeetByCustomerSelector = (state) => state.meetByCustomer;
export const MeetByStatusSelector = (state) => state.meetByStatus;
export const MeetByStoreSelector = (state) => state.meetByStore;

export const MeetCountReducer = MeetCountSlice.reducer;
export const MeetByCustomerReducer = MeetByCustomerSlice.reducer;
export const MeetByStatusReducer = MeetByStatusSlice.reducer;
export const MeetByStoreReducer = MeetByStoreSlice.reducer;
