import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createStatus, deleteStatus, fetchStatus, fetchStatusDetail, updateStatus } from "src/services/status.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedStatus: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchAppStatus = createAsyncThunk(
  "fetch/status",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchStatus(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchStatusDetails = createAsyncThunk(
  "fetch/status/details",
  async (userID, { rejectWithValue }) => {
    try {
      return await fetchStatusDetail(userID);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const CreateStatus = createAsyncThunk(
  "create/status",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createStatus(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UpdateStatus = createAsyncThunk(
  "update/status",
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateStatus(params.id, params.values);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteStatus = createAsyncThunk(
  "delete/status",
  async (eventID, { rejectWithValue }) => {
    try {
      const response = await deleteStatus(eventID);
      return { ...response.data, deleteID: eventID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const StatusReducer = createSlice({
  name: "Status",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchAppStatus.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.selectedStatus = null;
    },
    [FetchAppStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchAppStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchStatusDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedStatus = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchStatusDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedStatus = action.payload;
    },
    [FetchStatusDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [CreateStatus.pending]: (state, action) => {
      state.loading = true;
      state.isCreated = false;
      state.errors = null;
    },
    [CreateStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCreated = true;
    },
    [CreateStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UpdateStatus.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdateStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdateStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeleteStatus.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.gridData.results = state.gridData.results.filter(
        (item) => item.id !== action.payload.deleteID
      );
    },
    [DeleteStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

// export const { removeAuth } = SponsorReducer.actions
export const statusSelector = (state) => state.statusList;
export default StatusReducer.reducer;
