import apiClient from "./axios"
import store from 'store';

export const userLogin = async (creds) => {
    const response = await apiClient.post('auth/admin/login/', creds)
    if (response) {
        const { access, refresh } = response.data
        if (access) {
            store.set('accessToken', access)
            store.set('refreshToken', refresh)
            const me = await apiClient.get('users/me')
            response.data.me = me.data;
        }

        return response.data
    }
    return false
}