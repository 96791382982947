import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    sidebarShow: 'responsive',
    darkMode: true
}

const themeSlice = createSlice({
    name: 'theme',
    initialState: initialState,
    reducers: {
        setSidebarShow: (state, action) => {
            state.sidebarShow = action.payload
        },
    
        setDarkMode: (state, action) => {
            state.darkMode = action.payload
        }
    },
})

export const { setSidebarShow, setDarkMode } = themeSlice.actions

// A selector
export const themeSelector = state => state.theme

export default themeSlice.reducer