import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addLottieFile,
  createStore,
  deleteStore,
  fetchCustomMedia,
  fetchFeatureAndAmenities,
  fetchStoreDetail,
  fetchStores,
  updateCustomMedia,
  updateFeatureAndAmenities,
  updateStore,
} from "src/services/store.services";

const initialState = {
  loading: false,
  lottieFileLoad: false,
  lottieCreated: false,
  gridData: null,
  selectedStore: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
  mediaData: null,
  isMediaUpdate: false,
  mediaLoading: false,
  featureLoading: false,
  featureErrors: null,
  isFeatureUpdate: false,
  FeatureData: {
    features: [],
    amenities: [],
  },
};

export const FetchStore = createAsyncThunk(
  "fetch/store",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchStores(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchStoreDetails = createAsyncThunk(
  "fetch/store/details",
  async (storeID, { rejectWithValue }) => {
    try {
      return await fetchStoreDetail(storeID);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const CreateStore = createAsyncThunk(
  "create/store",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createStore(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const addLottieFileToStore = createAsyncThunk(
  "create/store/lottie",
  async (params, { rejectWithValue }) => {
    try {
      const response = await addLottieFile(params.id, params.body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UpdateStore = createAsyncThunk(
  "update/store",
  async (params, { rejectWithValue }) => {
    const value = params.values;
    try {
      const response = await updateStore(params.id, value);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteStore = createAsyncThunk(
  "delete/store",
  async (storeID, { rejectWithValue }) => {
    try {
      const response = await deleteStore(storeID);
      return { ...response.data, deleteID: storeID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchCustomMedia = createAsyncThunk(
  "fetch/custom-media",
  async (id, { rejectWithValue }) => {
    try {
      return await fetchCustomMedia(id);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);
export const UpdateCustomMedia = createAsyncThunk(
  "update/custom-media",
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateCustomMedia(params.id, {
        fields: params.value,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchFeatureAndAmenities = createAsyncThunk(
  "fetch/feature-and-amenities",
  async (id, { rejectWithValue }) => {
    try {
      return await fetchFeatureAndAmenities(id);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);
export const UpdateFeatureAndAmenities = createAsyncThunk(
  "update/feature-and-amenities",
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateFeatureAndAmenities(params.id, params.value);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const storeSlice = createSlice({
  name: "store",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchStore.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDelete = false;
      state.selectedStore = null;
      state.mediaData = null;
      state.isMediaUpdate = false;
      state.mediaLoading = false;
    },
    [FetchStore.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchStore.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchStoreDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedStore = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchStoreDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedStore = action.payload;
    },
    [FetchStoreDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [CreateStore.pending]: (state, action) => {
      state.loading = true;
      state.isCreated = false;
      state.errors = null;
    },
    [CreateStore.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCreated = true;
    },
    [CreateStore.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [addLottieFileToStore.pending]: (state, action) => {
      state.lottieFileLoad = true;
      state.lottieCreated = false;
      state.errors = null;
    },
    [addLottieFileToStore.fulfilled]: (state, action) => {
      state.lottieFileLoad = false;
      state.lottieCreated = true;
    },
    [addLottieFileToStore.rejected]: (state, action) => {
      state.lottieFileLoad = false;
      state.errors = action.payload;
    },
    [UpdateStore.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdateStore.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdateStore.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeleteStore.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteStore.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.gridData.results = state.gridData.results.filter(
        (item) => item.id !== action.payload.deleteID
      );
    },
    [DeleteStore.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    // Custom Field
    [FetchCustomMedia.pending]: (state, action) => {
      state.mediaLoading = true;
      state.errors = null;
      state.isMediaUpdate = false;
    },
    [FetchCustomMedia.fulfilled]: (state, action) => {
      state.mediaLoading = false;
      state.mediaData = action.payload;
    },
    [FetchCustomMedia.rejected]: (state, action) => {
      state.mediaLoading = false;
      state.errors = action.payload;
    },
    [UpdateCustomMedia.pending]: (state, action) => {
      state.mediaLoading = true;
      state.isMediaUpdate = false;
      state.errors = null;
    },
    [UpdateCustomMedia.fulfilled]: (state, action) => {
      state.mediaLoading = false;
      state.isMediaUpdate = true;
    },
    [UpdateCustomMedia.rejected]: (state, action) => {
      state.mediaLoading = false;
      state.errors = action.payload;
    },
    [FetchFeatureAndAmenities.pending]: (state, action) => {
      state.featureLoading = true;
      state.featureErrors = null;
      state.isFeatureUpdate = false;
    },
    [FetchFeatureAndAmenities.fulfilled]: (state, action) => {
      state.featureLoading = false;
      state.FeatureData = action.payload;
    },
    [FetchFeatureAndAmenities.rejected]: (state, action) => {
      state.featureLoading = false;
      state.featureErrors = action.payload;
    },
    [UpdateFeatureAndAmenities.pending]: (state, action) => {
      state.featureLoading = true;
      state.isFeatureUpdate = false;
      state.featureErrors = null;
    },
    [UpdateFeatureAndAmenities.fulfilled]: (state, action) => {
      state.featureLoading = false;
      state.isFeatureUpdate = true;
    },
    [UpdateFeatureAndAmenities.rejected]: (state, action) => {
      state.featureLoading = false;
      state.featureErrors = action.payload;
    },
  },
});

export const storeSelector = (state) => state.store;
export default storeSlice.reducer;
