import apiClient from "./axios";

export const fetchEvent = async (params) => {
  const response = await apiClient.get(`admin/events/`, {
    params,
  });
  return response.data;
};

export const fetchEventDetail = async (userID) => {
  const response = await apiClient.get(`admin/events/${userID}/`);
  return response.data;
};

export const updateEvent = async (userID, params) => {
  const response = await apiClient.put(
    `admin/events/${userID}/`,
    params
  );
  return response.data;
};

export const createEvent = async (params) => {
  const response = await apiClient.post(`admin/events/`, params);
  return response.data;
};

export const deleteEvent = async (EventID, params) => {
  const response = await apiClient.delete(
    `admin/events/${EventID}`,
    params
  );
  return response.data;
};
