import apiClient from "./axios";

export const fetchInterest = async (params) => {
  const response = await apiClient.get(`admin/users/interests/`, {
    params,
  });
  return response.data;
};

export const fetchInterestDetail = async (InterestID) => {
  const response = await apiClient.get(`admin/users/interests/${InterestID}/`);
  return response.data;
};

export const updateInterest = async (InterestID, params) => {
  const response = await apiClient.put(
    `admin/users/interests/${InterestID}/`,
    params
  );
  return response.data;
};

export const createInterest = async (params) => {
  const response = await apiClient.post(`admin/users/interests/`, params);
  return response.data;
};

export const deleteInterest = async (InterestID, params) => {
  const response = await apiClient.delete(
    `admin/users/interests/${InterestID}/`,
    params
  );
  return response.data;
};
