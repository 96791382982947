import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchOrderDetail, fetchOrderList } from 'src/services/order.services';

const initialState = {
    loading: false,
    gridData: null,
    selectedOrder: null,
    errors: null,
}

export const FetchOrders = createAsyncThunk('fetch/order-list', async (params, { rejectWithValue }) => {
    try {
        return await fetchOrderList(params);
    } catch (error) {
        return rejectWithValue(error.response.data || "")
    }
})

export const FetchOrderDetails = createAsyncThunk('fetch/order/details', async (orderID, { rejectWithValue }) => {
    try {
        return await fetchOrderDetail(orderID);
    } catch (error) {
        return rejectWithValue(error.response.data || "")
    }
})

const orderSlice = createSlice({
    name: 'order',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [FetchOrders.pending]: (state, action) => {
            state.loading = true;
            state.gridData = null;
            state.errors = null;
            state.selectedOrder = null;
        },
        [FetchOrders.fulfilled]: (state, action) => {
            state.loading = false;
            state.gridData = action.payload;
        },
        [FetchOrders.rejected]: (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        },
        [FetchOrderDetails.pending]: (state, action) => {
            state.loading = true;
            state.selectedOrder = null;
            state.errors = null;
        },
        [FetchOrderDetails.fulfilled]: (state, action) => {
            state.loading = false;
            state.selectedOrder = action.payload;
        },
        [FetchOrderDetails.rejected]: (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        },
    },
})

export const orderSelector = state => state.order;
export default orderSlice.reducer;