import apiClient from "./axios";

export const fetchStores = async (params) => {
  const response = await apiClient.get(`admin/stores`, { params });
  return response.data;
};

export const fetchStoreDetail = async (StoreID) => {
  const response = await apiClient.get(`admin/stores/${StoreID}`);
  return response.data;
};

export const updateStore = async (StoreID, params) => {
  const response = await apiClient.put(`admin/stores/${StoreID}/`, params);
  return response.data;
};

export const createStore = async (params) => {
  const response = await apiClient.post(`admin/stores/`, params);
  return response.data;
};

export const deleteStore = async (StoreID, params) => {
  const response = await apiClient.delete(`admin/stores/${StoreID}`, params);
  return response.data;
};

export const fetchCustomMedia = async (StoreID) => {
  const response = await apiClient.get(`admin/stores/${StoreID}/fields`);
  return response.data;
};

export const updateCustomMedia = async (StoreID, params) => {
  const response = await apiClient.post(
    `admin/stores/${StoreID}/fields/`,
    params
  );
  return response.data;
};

export const addLottieFile = async (StoreID, params) => {
  const response = await apiClient.put(
    `admin/stores/${StoreID}/lottie/`,
    params
  );
  return response.data;
};
export const fetchFeatureAndAmenities = async (StoreID) => {
  const response = await apiClient.get(`admin/stores/${StoreID}/features`);
  return response.data;
};

export const updateFeatureAndAmenities = async (StoreID, params) => {
  const response = await apiClient.post(
    `admin/stores/${StoreID}/features/`,
    params
  );
  return response.data;
};
