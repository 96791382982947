import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createEvent,
  fetchEvent,
  updateEvent,
  fetchEventDetail,
  deleteEvent,
} from "src/services/event.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedEvent: null,
  errors: null,
  isCreated: false,
  createdData: null,
  isUpdated: false,
};

export const FetchEvent = createAsyncThunk(
  "fetch/event",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchEvent(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchEventDetails = createAsyncThunk(
  "fetch/event/details",
  async (userID, { rejectWithValue }) => {
    try {
      return await fetchEventDetail(userID);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const CreateEvent = createAsyncThunk(
  "create/event",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createEvent(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UpdateEvent = createAsyncThunk(
  "update/event",
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateEvent(params?.id, params?.values);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteEvent = createAsyncThunk(
  "delete/event",
  async (eventID, { rejectWithValue }) => {
    try {
      const response = await deleteEvent(eventID);
      return { ...response.data, deleteID: eventID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const eventSlice = createSlice({
  name: "Event",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchEvent.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.selectedEvent = null;
    },
    [FetchEvent.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchEvent.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchEventDetails.pending]: (state, action) => {
      state.isCreated = false;
      state.loading = true;
      state.selectedEvent = null;
      state.errors = null;
      state.isUpdated = false;
    },
    [FetchEventDetails.fulfilled]: (state, action) => {
      state.isCreated = false;
      state.loading = false;
      state.selectedEvent = action.payload;
    },
    [FetchEventDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [CreateEvent.pending]: (state, action) => {
      state.loading = true;
      state.isCreated = false;
      state.errors = null;
    },
    [CreateEvent.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCreated = true;
      state.createdData = action.payload;
    },
    [CreateEvent.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UpdateEvent.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdateEvent.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdateEvent.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeleteEvent.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteEvent.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.gridData.results = state.gridData.results.filter(
        (item) => item.id !== action.payload.deleteID
      );
    },
    [DeleteEvent.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

// export const { removeAuth } = eventSlice.actions
export const eventSelector = (state) => state.event;
export default eventSlice.reducer;
