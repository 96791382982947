import apiClient from "./axios"

export const fetchOrderList = async (params) => {
    const response = await apiClient.get(`admin/e/orders/`, { params });
    return response.data
}

export const fetchOrderDetail = async (orderID) => {
    const response = await apiClient.get(`admin/e/orders/${orderID}/`);
    return response.data
}

export const fetchEventOrderList = async (params) => {
    const response = await apiClient.get(`admin/e/orders/`, { params });
    return response.data
}