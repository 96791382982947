import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createAppUser,
  deleteAppUser,
  fetchAppUserDetail,
  fetchAppUsers,
  updateAppUser,
} from "src/services/appUsers.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedUser: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchAppUsers = createAsyncThunk(
  "fetch/app-users",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchAppUsers(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchAppUserDetails = createAsyncThunk(
  "fetch/app-users/details",
  async (userID, { rejectWithValue }) => {
    try {
      return await fetchAppUserDetail(userID);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const CreateAppUser = createAsyncThunk(
  "create/app-user",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createAppUser(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UpdateAppUser = createAsyncThunk(
  "update/app-user",
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateAppUser(params.id, params.values);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteAppUser = createAsyncThunk(
  "delete/app-user",
  async (appUserID, { rejectWithValue }) => {
    try {
      const response = await deleteAppUser(appUserID);
      return { ...response.data, deleteID: appUserID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const appUserSlice = createSlice({
  name: "appUser",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchAppUsers.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.selectedUser = null;
    },
    [FetchAppUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchAppUsers.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchAppUserDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedUser = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchAppUserDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedUser = action.payload;
    },
    [FetchAppUserDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [CreateAppUser.pending]: (state, action) => {
      state.loading = true;
      state.isCreated = false;
      state.errors = null;
    },
    [CreateAppUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCreated = true;
    },
    [CreateAppUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UpdateAppUser.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdateAppUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdateAppUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeleteAppUser.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteAppUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.gridData.results = state.gridData.results.filter(
        (item) => item.id !== action.payload.deleteID
      );
    },
    [DeleteAppUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

// export const { removeAuth } = appUserSlice.actions
export const appUserSelector = (state) => state.appUser;
export default appUserSlice.reducer;
