import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createInterest,
  deleteInterest,
  fetchInterest,
  fetchInterestDetail,
  updateInterest,
} from "src/services/interest.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedTags: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchInterest = createAsyncThunk(
  "fetch/users/interest",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchInterest(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchInterestDetails = createAsyncThunk(
  "fetch/users/interest/details",
  async (InterestID, { rejectWithValue }) => {
    try {
      return await fetchInterestDetail(InterestID);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const CreateInterest = createAsyncThunk(
  "create/users/interest",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createInterest(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UpdateInterest = createAsyncThunk(
  "update/users/interest",
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateInterest(params.id, params.values);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteInterest = createAsyncThunk(
  "delete/users/interest",
  async (InterestID, { rejectWithValue }) => {
    try {
      const response = await deleteInterest(InterestID);
      return { ...response.data, deleteID: InterestID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const InterestReducer = createSlice({
  name: "Interest",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchInterest.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.selectedTags = null;
    },
    [FetchInterest.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchInterest.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchInterestDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedTags = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchInterestDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedTags = action.payload;
    },
    [FetchInterestDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [CreateInterest.pending]: (state, action) => {
      state.loading = true;
      state.isCreated = false;
      state.errors = null;
    },
    [CreateInterest.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCreated = true;
    },
    [CreateInterest.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UpdateInterest.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdateInterest.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdateInterest.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeleteInterest.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteInterest.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.gridData.results = state.gridData.results.filter(
        (item) => item.id !== action.payload.deleteID
      );
    },
    [DeleteInterest.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

// export const { removeAuth } = TagsReducer.actions
export const interestSelector = (state) => state.interestList;
export default InterestReducer.reducer;
