import apiClient from "./axios";

export const fetchAmenities = async (params) => {
  const response = await apiClient.get(`admin/stores/amenities/`, { params });
  return response.data;
};

export const fetchAmenitiesDetail = async (AmenitiesID) => {
  const response = await apiClient.get(`admin/stores/amenities/${AmenitiesID}`);
  return response.data;
};

export const updateAmenities = async (AmenitiesID, params) => {
  const response = await apiClient.put(
    `admin/stores/amenities/${AmenitiesID}/`,
    params
  );
  return response.data;
};

export const createAmenities = async (params) => {
  const response = await apiClient.post(`admin/stores/amenities/`, params);
  return response.data;
};

export const deleteAmenities = async (AmenitiesID, params) => {
  const response = await apiClient.delete(
    `admin/stores/amenities/${AmenitiesID}`,
    params
  );
  return response.data;
};
