import apiClient from "./axios";

export const fetchFeedbacks = async (params) => {
  const response = await apiClient.get(`admin/users/feedbacks/`, { params });
  return response.data;
};

export const fetchFeedbackDetail = async (ID) => {
  const response = await apiClient.get(`admin/users/feedbacks/${ID}/`);
  return response.data;
};

export const deleteFeedback = async (ID, params) => {
  const response = await apiClient.delete(
    `admin/users/feedbacks/${ID}`,
    params
  );
  return response.data;
};
