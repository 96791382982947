import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createTicket,
  fetchTicket,
  updateTicket,
  deleteTicket,
} from "src/services/ticket.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedEvent: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
  isDelete: false,
  createdData: null
};

export const FetchTicket = createAsyncThunk(
  "fetch/event/ticket",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchTicket(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

// export const FetchEventDetails = createAsyncThunk(
//   "fetch/tic/details",
//   async (userID, { rejectWithValue }) => {
//     try {
//       return await fetchEventDetail(userID);
//     } catch (error) {
//       return rejectWithValue(error.response.data || "");
//     }
//   }
// );

export const CreateTicket = createAsyncThunk(
  "create/event/ticket",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createTicket(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UpdateTicket = createAsyncThunk(
  "update/event/ticket",
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateTicket(params?.id, params?.values);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteTicket = createAsyncThunk(
  "delete/event/ticket",
  async (ticketID, { rejectWithValue }) => {
    try {
      const response = await deleteTicket(ticketID);
      console.log('response', response);
      return { ...response.data, deleteID: ticketID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const ticketSlice = createSlice({
  name: "Ticket",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchTicket.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.selectedEvent = null;
    },
    [FetchTicket.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchTicket.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    // [FetchEventDetails.pending]: (state, action) => {
    //   state.loading = true;
    //   state.selectedEvent = null;
    //   state.errors = null;
    //   state.isCreated = false;
    //   state.isUpdated = false;
    // },
    // [FetchEventDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.selectedEvent = action.payload;
    // },
    // [FetchEventDetails.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    [CreateTicket.pending]: (state, action) => {
      state.loading = true;
      state.isCreated = false;
      state.errors = null;
    },
    [CreateTicket.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCreated = true;
      state.createdData = action.payload
    },
    [CreateTicket.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UpdateTicket.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdateTicket.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdateTicket.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeleteTicket.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteTicket.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
    },
    [DeleteTicket.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

// export const { removeAuth } = eventSlice.actions
export const ticketSelector = (state) => state.ticket;
export default ticketSlice.reducer;
