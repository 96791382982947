import apiClient from "./axios";

export const fetchCategories = async (params) => {
  const response = await apiClient.get(`admin/posts/categories/`, {
    params,
  });
  return response.data;
};

export const fetchCategoryDetail = async (CategoryID) => {
  const response = await apiClient.get(`admin/posts/categories/${CategoryID}/`);
  return response.data;
};

export const updateCategory = async (CategoryID, params) => {
  const response = await apiClient.put(
    `admin/posts/categories/${CategoryID}/`,
    params
  );
  return response.data;
};

export const createCategory = async (params) => {
  const response = await apiClient.post(`admin/posts/categories/`, params);
  return response.data;
};

export const deleteCategory = async (CategoryID, params) => {
  const response = await apiClient.delete(
    `admin/posts/categories/${CategoryID}/`,
    params
  );
  return response.data;
};

