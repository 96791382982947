import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchMeetRequests } from "src/services/meetRequest.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedTags: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchMeetRequest = createAsyncThunk(
  "fetch/meetRequests",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchMeetRequests(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

// export const FetchPostDetails = createAsyncThunk('fetch/posts/details', async (postId, { rejectWithValue }) => {
//   try {
//     const response = await fetchPostDetail(postId);

//     return response

//   } catch (error) {
//     return rejectWithValue(error.response.data || "")
//   }
// })

// export const CreatePost = createAsyncThunk('create/posts', async (params, { rejectWithValue }) => {
//   try {
//     const response = await createPost(params)
//     return response.data
//   } catch (error) {
//     return rejectWithValue(error.response.data || "")
//   }
// })

// export const UpdatePost = createAsyncThunk('update/posts', async (params, { rejectWithValue }) => {
//   try {
//     const response = await updatePost(params.id, params.values)
//     return response
//   } catch (error) {
//     return rejectWithValue(error.response.data || "")
//   }
// })

// export const DeletePost = createAsyncThunk(
//   "delete/posts",
//   async (postId, { rejectWithValue }) => {
//     try {
//       const response = await deletePost(postId);
//       return { ...response.data, deleteID: postId };
//     } catch (error) {
//       return rejectWithValue(error.response.data || "");
//     }
//   }
// );

const MeetRequestReducer = createSlice({
  name: "Meet Request",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchMeetRequest.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.selectedTags = null;
    },
    [FetchMeetRequest.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchMeetRequest.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    // [FetchPostDetails.pending]: (state, action) => {
    //   state.loading = true;
    //   state.selectedTags = null;
    //   state.errors = null;
    //   state.isCreated = false;
    //   state.isUpdated = false;
    // },
    // [FetchPostDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.selectedTags = action.payload;
    // },
    // [FetchPostDetails.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    // [CreatePost.pending]: (state, action) => {
    //   state.loading = true;
    //   state.isCreated = false;
    //   state.errors = null;
    // },
    // [CreatePost.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.isCreated = true;
    // },
    // [CreatePost.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    // [UpdatePost.pending]: (state, action) => {
    //   state.loading = true;
    //   state.isUpdated = false;
    //   state.errors = null;
    // },
    // [UpdatePost.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.isUpdated = true;
    // },
    // [UpdatePost.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    // [DeletePost.pending]: (state, action) => {
    //   state.loading = true;
    //   state.isDelete = false;
    //   state.errors = null;
    // },
    // [DeletePost.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.isDelete = true;
    //   // state.gridData.results = state.gridData.results.filter(
    //   //   (item) => item.id !== action.payload.deleteID
    //   // );
    // },
    // [DeletePost.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
  },
});

// export const { removeAuth } = TagsReducer.actions
export const meetRequestsSelector = (state) => state.meetRequest;
export default MeetRequestReducer.reducer;
