import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteFeedback,
  fetchFeedbackDetail,
  fetchFeedbacks,
} from "src/services/feedback.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedFeedback: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchFeedbacks = createAsyncThunk(
  "fetch/users/feedbacks",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchFeedbacks(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchFeedbackDetails = createAsyncThunk(
  "fetch/users/feedback/details",
  async (userID, { rejectWithValue }) => {
    try {
      return await fetchFeedbackDetail(userID);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteFeedback = createAsyncThunk(
  "delete/user/feedbacks",
  async (userID, { rejectWithValue }) => {
    try {
      const response = await deleteFeedback(userID);
      return { ...response.data, deleteID: userID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const feedbackSlice = createSlice({
  name: "feedback",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchFeedbacks.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.selectedFeedback = null;
    },
    [FetchFeedbacks.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchFeedbacks.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchFeedbackDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedFeedback = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchFeedbackDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedFeedback = action.payload;
    },
    [FetchFeedbackDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [DeleteFeedback.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteFeedback.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.gridData.results = state.gridData.results.filter(
        (item) => item.id !== action.payload.deleteID
      );
    },
    [DeleteFeedback.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

// export const { removeAuth } = userSlice.actions
export const feedbackSelector = (state) => state.feedback;
export default feedbackSlice.reducer;
