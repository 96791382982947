import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  deleteReport,
  fetchReportDetail,
  fetchReports,
  updateReport,
} from "src/services/report.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedReport: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchReports = createAsyncThunk(
  "fetch/users/reports",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchReports(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchReportDetails = createAsyncThunk(
  "fetch/users/reports/details",
  async (userID, { rejectWithValue }) => {
    try {
      return await fetchReportDetail(userID);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UpdateReport = createAsyncThunk(
  "update/user/report",
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateReport(params.id, params.values);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteReport = createAsyncThunk(
  "delete/user/report",
  async (userID, { rejectWithValue }) => {
    try {
      const response = await deleteReport(userID);
      return { ...response.data, deleteID: userID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchReports.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.selectedReport = null;
    },
    [FetchReports.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchReports.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchReportDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedReport = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchReportDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedReport = action.payload;
    },
    [FetchReportDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UpdateReport.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdateReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdateReport.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeleteReport.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.gridData.results = state.gridData.results.filter(
        (item) => item.id !== action.payload.deleteID
      );
    },
    [DeleteReport.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

// export const { removeAuth } = userSlice.actions
export const reportSelector = (state) => state.report;
export default reportSlice.reducer;
