import apiClient from "./axios";

export const fetchUsers = async (params) => {
  const response = await apiClient.get(`admin/users`, { params });
  return response.data;
};

export const fetchUserDetail = async (userID) => {
  const response = await apiClient.get(`admin/users/${userID}/`);
  return response.data;
};

export const updateUser = async (userID, params) => {
  const response = await apiClient.put(`admin/users/${userID}/`, params);
  return response.data;
};

export const createUser = async (params) => {
  const response = await apiClient.post(`admin/users/`, params);
  return response.data;
};

export const deleteUser = async (UserID, params) => {
  const response = await apiClient.delete(
    `admin/users/${UserID}`,
    params
  );
  return response.data;
};
