import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createArtist,
  fetchArtist,
  updateArtist,
  fetchArtistDetail,
  deleteArtist,
} from "src/services/artist.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedArtist: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchAppArtist = createAsyncThunk(
  "fetch/artist",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchArtist(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchArtistDetails = createAsyncThunk(
  "fetch/app-users/details",
  async (userID, { rejectWithValue }) => {
    try {
      return await fetchArtistDetail(userID);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const CreateArtist = createAsyncThunk(
  "create/app-user",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createArtist(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UpdateArtist = createAsyncThunk(
  "update/app-user",
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateArtist(params.id, params.values);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteArtist = createAsyncThunk(
  "delete/artist",
  async (artistID, { rejectWithValue }) => {
    try {
      const response = await deleteArtist(artistID);
      return { ...response.data, deleteID: artistID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const artistSclice = createSlice({
  name: "Artist",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchAppArtist.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.selectedArtist = null;
    },
    [FetchAppArtist.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchAppArtist.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchArtistDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedArtist = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchArtistDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedArtist = action.payload;
    },
    [FetchArtistDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [CreateArtist.pending]: (state, action) => {
      state.loading = true;
      state.isCreated = false;
      state.errors = null;
    },
    [CreateArtist.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCreated = true;
    },
    [CreateArtist.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UpdateArtist.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdateArtist.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdateArtist.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeleteArtist.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteArtist.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.gridData.results = state.gridData.results.filter(
        (item) => item.id !== action.payload.deleteID
      );
    },
    [DeleteArtist.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

// export const { removeAuth } = artistSclice.actions
export const appArtistSelector = (state) => state.artistList;
export default artistSclice.reducer;
