import apiClient from "./axios";

export const fetchArtist = async (params) => {
  const response = await apiClient.get(`admin/events/artists/`, {
    params,
  });
  return response.data;
};

export const fetchArtistDetail = async (userID) => {
  const response = await apiClient.get(`admin/events/artists/${userID}/`);
  return response.data;
};

export const updateArtist = async (userID, params) => {
  const response = await apiClient.put(
    `admin/events/artists/${userID}/`,
    params
  );
  return response.data;
};

export const createArtist = async (params) => {
  const response = await apiClient.post(`admin/events/artists/`, params);
  return response.data;
};

export const deleteArtist = async (ArtistID, params) => {
  const response = await apiClient.delete(
    `admin/events/artists/${ArtistID}`,
    params
  );
  return response.data;
};
