import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userLogin } from 'src/services/auth.services';
import store from "store";

const initialState = {
    loading: false,
    isAuthenticated: false,
    userData: {},
    errors: null
}

export const DoLogin = createAsyncThunk('auth/admin/login', async (credentials, { rejectWithValue }) => {
    try {
        const response = await userLogin(credentials)
        return response
    } catch (error) {
        return rejectWithValue(error.response.data.detail || "")
    }
})

export const LogOut = createAsyncThunk('auth/admin/logout', async (params) => {
    store.remove('accessToken')
    store.remove('refreshToken')
    return true
})

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [DoLogin.pending]: (state, action) => {
            state.loading = true;
            state.isAuthenticated = false;
            state.userData = {};
            state.errors = null;
        },
        [DoLogin.fulfilled]: (state, action) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.userData = action.payload;
            state.errors = null;
        },
        [DoLogin.rejected]: (state, action) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.userData = {};
            state.errors = action.payload;
        },
        [LogOut.fulfilled]: (state) => {
            state.isAuthenticated = false;
            state.userData = {};
            state.loading = false;
        }
    },
})

// export const { removeAuth } = authSlice.actions;
export const authSelector = state => state.auth;
export default authSlice.reducer;