import axios from "axios";
import store from "store";
import reduxStore from "src/redux/store";
import { LogOut } from "src/redux/slices/auth.slice";

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
});

apiClient.interceptors.request.use((request) => {
  const accessToken = store.get("accessToken");
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }
  return request;
});
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (
      err.response.status === 401 &&
      (err.response.data.code === "token_not_valid" ||
        err.response.data.detail ===
          "Authentication credentials were not provided.")
    ) {
      reduxStore.dispatch(LogOut());
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
);

export default apiClient;
