import apiClient from "./axios";

export const fetchReports = async (params) => {
  const response = await apiClient.get(`admin/users/reports/`, { params });
  return response.data;
};

export const fetchReportDetail = async (ID) => {
  const response = await apiClient.get(`admin/users/reports/${ID}/`);
  return response.data;
};

export const updateReport = async (ID, params) => {
  const response = await apiClient.put(`admin/users/reports/${ID}/`, params);
  return response.data;
};

export const deleteReport = async (ID, params) => {
  const response = await apiClient.delete(`admin/users/reports/${ID}/`, params);
  return response.data;
};
