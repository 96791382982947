import { combineReducers } from "redux";
import AuthReducer from "./auth.slice";
import ThemeReducer from "./theme.slice";
import UserReducer from "./user.slice";
import StoreReducer from "./store.slice";
import WorkSocialReducer from "./workSocial.slice";
import AppUserReducer from "./appUser.slice";
import OrderReducer from "./order.slice";
import MediaReducer from "./media.slice";
import FeatureReducer from "./features.slice";
import AmenitiesReducer from "./amenities.slice";
import ArtistReducer from "./artist.slice";
import SponsorReducer from "./sponsors.slice";
import TagsReducer from "./tags.slice";
import EventReducer from "./event.slice";
import StatusReducer from "./status.slice";
import TicketReducer from "./ticket.slice";
import CategoryReducer from "./categories.slice";
import PostReducer from "./posts.slice";
import InterestReducer from "./interest.slice";
import MeetRequestReducer from "./meetRequest.slice";
import FeedbackReducer from "./feedback.slice";
import ReportReducer from "./report.slice";

import {
  MeetByCustomerReducer,
  MeetByStatusReducer,
  MeetByStoreReducer,
  MeetCountReducer,
} from "./meetAnalytics";
import { PostCountReducer } from "./postAnalytics.slice";

const rootReducers = combineReducers({
  auth: AuthReducer,
  theme: ThemeReducer,
  user: UserReducer,
  appUser: AppUserReducer,
  event: EventReducer,
  artistList: ArtistReducer,
  sponsorList: SponsorReducer,
  statusList: StatusReducer,
  tagsList: TagsReducer,
  store: StoreReducer,
  workSocial: WorkSocialReducer,
  order: OrderReducer,
  media: MediaReducer,
  feature: FeatureReducer,
  amenities: AmenitiesReducer,
  ticket: TicketReducer,
  categoryList: CategoryReducer,
  post: PostReducer,
  interestList: InterestReducer,
  meetRequest: MeetRequestReducer,
  meetCount: MeetCountReducer,
  meetByCustomer: MeetByCustomerReducer,
  meetByStatus: MeetByStatusReducer,
  meetByStore: MeetByStoreReducer,
  postCount: PostCountReducer,
  feedback: FeedbackReducer,
  report: ReportReducer,
});

export default rootReducers;
