import apiClient from "./axios";

export const getMeetRequestCount = async (params) => {
  const response = await apiClient.get(`admin/meet-requests/analytics/count/`, {
    params,
  });
  return response.data;
};
export const getMeetRequestByCustomer = async (params) => {
  const response = await apiClient.get(
    `admin/meet-requests/analytics/count-by-customer/`,
    {
      params,
    }
  );

  return response.data;
};
export const getMeetRequestByStatus = async (params) => {
  const response = await apiClient.get(
    `admin/meet-requests/analytics/count-by-status/`,
    {
      params,
    }
  );

  return response.data;
};
export const getMeetRequestByStore = async (params) => {
  const response = await apiClient.get(
    `admin/meet-requests/analytics/count-by-store/`,
    {
      params,
    }
  );

  return response.data;
};
