import apiClient from "./axios";

export const fetchTicket = async (params) => {
  const response = await apiClient.get(`admin/events/tickets/`, {
    params,
  });
  return response.data;
};

export const updateTicket = async (id, params) => {
  const response = await apiClient.put(
    `admin/events/tickets/${id}/`,
    params
  );
  return response.data;
};

export const createTicket = async (params) => {
  const response = await apiClient.post(`admin/events/tickets/`, params);
  return response.data;
};

export const deleteTicket = async (ticketID, params) => {
  const response = await apiClient.delete(
    `admin/events/tickets/${ticketID}/`,
    params
  );
  return response.data;
};
