import apiClient from "./axios";

export const fetchPosts = async (params) => {
  const response = await apiClient.get(`admin/posts/`, {
    params,
  });
  return response.data;
};
export const fetchPostReports = async (params) => {
  const response = await apiClient.get(`admin/posts/post_reports/`, {
    params,
  });
  return response.data;
};

export const fetchPostDetail = async (postId) => {
  const response = await apiClient.get(`admin/posts/${postId}/`);

  return response.data;
};
export const fetchPostReportDetail = async (postId) => {
  const response = await apiClient.get(`admin/posts/post_reports/${postId}/`);

  return response.data;
};

export const updatePost = async (postId, params) => {
  const response = await apiClient.put(`admin/posts/${postId}/status/`, params);
  return response.data;
};

export const createPost = async (params) => {
  const response = await apiClient.post(`admin/posts/`, params);
  return response.data;
};

export const deletePost = async (postId, params) => {
  const response = await apiClient.delete(`admin/posts/${postId}/`, params);
  return response.data;
};
export const deletePostReport = async (postId, params) => {
  const response = await apiClient.delete(
    `admin/posts/post_reports/${postId}/`,
    params
  );
  return response.data;
};
