import apiClient from "./axios";

export const fetchStatus = async (params) => {
  const response = await apiClient.get(`admin/events/statuses/`, {
    params,
  });
  return response.data;
};

export const fetchStatusDetail = async (userID) => {
  const response = await apiClient.get(`admin/events/statuses/${userID}/`);
  return response.data;
};

export const updateStatus = async (userID, params) => {
  const response = await apiClient.put(
    `admin/events/statuses/${userID}/`,
    params
  );
  return response.data;
};

export const createStatus = async (params) => {
  const response = await apiClient.post(`admin/events/statuses/`, params);
  return response.data;
};

export const deleteStatus = async (StatusID, params) => {
  const response = await apiClient.delete(
    `admin/events/statuses/${StatusID}`,
    params
  );
  return response.data;
};