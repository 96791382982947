import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createFeature,
  deleteFeature,
  fetchFeatureDetail,
  fetchFeatures,
  updateFeature,
} from "src/services/features.services";

const initialState = {
  loading: false,
  gridData: null,
  selectedFeature: null,
  errors: null,
  isCreated: false,
  isUpdated: false,
};

export const FetchFeatures = createAsyncThunk(
  "fetch/features",
  async (params, { rejectWithValue }) => {
    try {
      return await fetchFeatures(params);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const FetchFeatureDetails = createAsyncThunk(
  "fetch/features/details",
  async (featureID, { rejectWithValue }) => {
    try {
      return await fetchFeatureDetail(featureID);
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const CreateFeature = createAsyncThunk(
  "create/feature",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createFeature(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const UpdateFeature = createAsyncThunk(
  "update/feature",
  async (params, { rejectWithValue }) => {
    const value = params.values;
    try {
      const response = await updateFeature(params.id, value);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

export const DeleteFeature = createAsyncThunk(
  "delete/feature",
  async (featureID, { rejectWithValue }) => {
    try {
      const response = await deleteFeature(featureID);
      return { ...response.data, deleteID: featureID };
    } catch (error) {
      return rejectWithValue(error.response.data || "");
    }
  }
);

const FeatureSlice = createSlice({
  name: "feature",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [FetchFeatures.pending]: (state, action) => {
      state.loading = true;
      state.gridData = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
      state.isDelete = false;
      state.selectedFeature = null;
    },
    [FetchFeatures.fulfilled]: (state, action) => {
      state.loading = false;
      state.gridData = action.payload;
    },
    [FetchFeatures.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [FetchFeatureDetails.pending]: (state, action) => {
      state.loading = true;
      state.selectedFeature = null;
      state.errors = null;
      state.isCreated = false;
      state.isUpdated = false;
    },
    [FetchFeatureDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedFeature = action.payload;
    },
    [FetchFeatureDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [CreateFeature.pending]: (state, action) => {
      state.loading = true;
      state.isCreated = false;
      state.errors = null;
    },
    [CreateFeature.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCreated = true;
    },
    [CreateFeature.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [UpdateFeature.pending]: (state, action) => {
      state.loading = true;
      state.isUpdated = false;
      state.errors = null;
    },
    [UpdateFeature.fulfilled]: (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    },
    [UpdateFeature.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [DeleteFeature.pending]: (state, action) => {
      state.loading = true;
      state.isDelete = false;
      state.errors = null;
    },
    [DeleteFeature.fulfilled]: (state, action) => {
      state.loading = false;
      state.isDelete = true;
      state.gridData.results = state.gridData.results.filter(
        (item) => item.id !== action.payload.deleteID
      );
    },
    [DeleteFeature.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const featureSelector = (state) => state.feature;
export default FeatureSlice.reducer;
